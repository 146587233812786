@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Romanesco&display=swap);
/* Resets */
*{
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
@media screen{
  .App{
      position: relative;
      background-color: var(--Col_bck);
      position: fixed;
      height: 100%;
      width:100vw;
      display: -webkit-flex;
      display: flex;
    }
  .contentPage.La_Carte>p:first-child{
    display: none;
  }
  .contentPage.Vins_au_verre>p:first-child{
    display: none;
  }
  .contentPage.Les_Vins>p:first-child{
    display: none;
  }
}
body{
  margin: 0;
}
  ::-webkit-scrollbar{
    width: 8px;
    height: 8px;
    padding: 12px;
  }
  ::-webkit-scrollbar-thumb{
    background-color: var(--Col_main);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background-color: #570000;
  }
  ::-webkit-scrollbar-track{
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: inset 7px 6.9px 12px 0px #D1D1D1;
    padding: 12px;
  }

  .InnerWindow{
  width:100%;
  }
  


  /* PAGE STYLING____________________________________________________________ */

  .contentPage h2 {
  font-size: 2.7rem;
  margin: 0;
  }
  .contentPage p {
  margin: 0;
  }
  .contentPage h2:not(:first-child) {
  margin-top: 50px;
  }
  .contentPage h2:first-child {
  margin-top: 20px;
  }
  .contentPage h3 {
  text-align: left !important;
  font-size: 2.2rem;
  margin-bottom: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 4px solid #686868;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: end;
          align-items: end;
  padding: 0 10px;
  }
  .contentPage h3 span{
  font-size: 0.75rem;
  text-align: right;
  }
  .contentPage tr:not(:first-child) td h3 {
  margin-top: 50px;
  }


  #contentBlock{
  width: 100%;
  height: 100%;
  padding: 0 12px 45px;
  font-size: 0.85rem;
  }
  .contentPage.Les_Menus figure , .contentPage.La_Carte figure{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100% !important;
  }
  .contentPage.Les_Menus p, .contentPage.La_Carte p{
  margin: 12px;
  }
  .contentPage.Les_Menus h2, .contentPage.La_Carte h2{
  margin-bottom: 35px;
  }
  .contentPage.Les_Menus .text-big{
  font-size: 1.2rem;
  }
  .contentPage.Les_Menus figure img , .contentPage.La_Carte figure img{
  width: 55px;
  }
  .contentPage{
  max-width: 1000px;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.212);
  padding: 25px 25px 45px;
  margin-bottom: 45px;
  margin-top: 45px;
  }
  .contentPage tr td tr td{
  padding: 0;
  }
  .contentPage tr td tr td p{
  margin: 0;
  }
  .contentPage table{
  margin: 0;
  width: 100%;
  }
  .contentPage figure{
  margin: 0;
  }
  .contentPage tbody{
  width: 100%;
  }
  .contentPage tr{
  width: 100%;
  }
  .contentPage td{
  box-sizing: border-box;
  min-width: 80px;
  padding: 10px 12px;
  width: 100%;
  }
  .contentPage td p{
  width: 100%;
  }
  .text-tiny{
  -webkit-font-feature-settings: "sups";
          font-feature-settings: "sups";
  font-variant-position: super; 
  }


  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr:first-child{
  display: none;
  }
  .contentPageContainer.Vins_au_verre h3{
    font-size: 3rem;
  }

@media screen AND (min-width: 1000px) {
  .contentPage h2::before{
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url(/static/media/orn-left.9c22537d.svg);
      background-repeat: no-repeat;
      background-position: right;
  }
  
  .contentPage h2::after {
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url(/static/media/orn-right.3a9c73ce.svg);
      background-repeat: no-repeat;
      background-position: left;
  }
  .contentPage:not(.Le_Bar) h4::before,
  .contentPage:not(.Le_Bar) h4::after {
    display: inline-block;
    content: "";
    border-top: 2px solid #686868;
    width: 4rem;
    margin: 0 1rem;
    -webkit-transform: translateY(-0.3rem);
            transform: translateY(-0.3rem);
  }
  .contentPage.Le_Bar h4
  {
    text-align: left !important;
    padding-left: 15px;
    margin-bottom: 0;
  }

  .contentPage>.table>table>tbody>tr>td:nth-last-child(2):not(:first-child){
    min-width: 100px;
  }
  .contentPage>.table>table>tbody>tr>td:nth-last-child(3):not(:first-child),.contentPage>.table>table>tbody>tr>td:nth-last-child(4):not(:first-child){
    min-width: 150px;
  }
}

.contentPage.La_Carte tr{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  }
.contentPage.La_Carte tr td{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  }
.contentPage.La_Carte tr td:nth-child(2){
  font-size: 1.6rem;
  font-family: 'Romanesco', sans-serif;
  font-weight: 500;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  }


@media screen AND (max-width:1000px) {
  .contentPage.Vins_au_verre tr td:nth-child(2){
    display: none;
  }
  h4{
    font-size: 1.1rem;
  }
  .contentPage.Le_Bar h4{
    margin-bottom: 0;
  }

  .contentPage td p:empty{
    display: none;
  }
  .contentPage>.table tr{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }
  .contentPage>.table>table>tbody>tr>td:first-child{
    font-size: 1.1rem !important;
    padding-bottom: 20;
    width: 100%;
  }
  .contentPage>.table>table>tbody>tr>td:first-child tr>td:nth-child(2){
    display: none;
  }

  .contentPage>.table tr>td:not(first-child){
    width: auto;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .contentPage h3 span{
    display: none;
  }
  .contentPage h3 {
    text-align: center !important;
    font-size: 2.4rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr:first-child{
    font-size: 0.75rem !important;
    width: 100%;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr:not(:first-child){
    font-size: 0.9rem !important;
    width: 100%;
    display: -webkit-flex;
    display: flex;
  }

  .contentPage>.table>table>tbody>tr:after{
    display: block;
      content: "";
      height: 18px;
      width: 100%;
      margin-top: 12px;
      background-image: url(/static/media/orn-right.3a9c73ce.svg);
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 45px;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr p{
    text-align: center;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr{
    padding: 10px;
    text-align: center;
    width: auto;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child){
    border-radius: 32px;
    text-align: center !important;
    width: 25%;
    padding: 0;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr{
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .contentPage.Les_Menus p, .contentPage.La_Carte p{
    font-size: 1.1rem;
  }
  .contentPage.Les_Menus .text-big{
    font-size: 1.2rem;
  }
}


.contentPage.Vins_au_verre h2{
  display: none;
  width: 100%;
}
*{
    font-family: 'Poppins', sans-serif;
    color: var(--Col_main);
}

h4,h5,h6{
    font-family: 'Open Sans', sans-serif;
}
h1, h2, h3, h4.title, h5.title, h6.title{
    font-family: 'Romanesco', sans-serif;
    font-weight: 500;
}
:root{
    /* --Col_main: #3b3640;
    --Col_sec: #f8005f;
    --Col_bck: #e49f5e;
    --Col_contrast: #f3cca2; */
    --Col_main: #3b3640;
    --Col_sec: #896394;
    --Col_bck: #ddc3b9;
    --Col_contrast: #b08ab8;
    --Col_main: #122f3c;
    --Col_sec: #E00000;
    --Col_bck: #d67b50;
    --Col_contrast: #ffe6c5;
    /* --Col_main: #3b3640;
    --Col_sec: #ac0a0a;
    --Col_bck: #370303;
    --Col_contrast: #e68a8a; */
}

@media print {
    .contentPage.La_Carte>p:first-child{
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
    }
    .contentPage.Vins_au_verre>p:first-child{
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
    }
    .contentPage.Les_Vins>p:first-child{
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
    }
    body{
        margin: 0;
        width: 100vw;
    }
    #root, #app, .InnerWindow{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    /* html{
        background-color: blue;
    } */
    html *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
    h1,#mainTitle,.Translate,#SideBar{
        display:  none;
    }
    .contentPage{
        margin: 0;
    }
    .contentPageContainer{
        margin: 0;
        overflow: hidden;
        /* background-color: red;
        border: 3px solid black; */
        width: 100vw;
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: relative;
        left: -13px;
        top: -1px;
    }
    .contentPageContainer.La_Carte{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .contentPageContainer.La_Carte:first-child{
        margin-bottom: 30px;
    }
    .laCarteSubContainer{
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }
    /* .contentPageContainer.La_Carte:nth-child(even){
            align-items: flex-start;
    }
    .contentPageContainer.La_Carte:nth-child(odd){
            align-items: flex-end;
            margin-bottom: 40px;
    } */
    .contentPageContainer *{
        max-width: 100vw;
        max-height: 100vh;
    }
    .contentPage{
        width: 100vw;
        box-shadow: none;
    }
    .contentPage.Les_Menus h2, .contentPage.La_Carte h2{
        margin-top: 0;
        font-size: 40px;
        margin-bottom: 5px !important;
        }
    .contentPage.La_Carte figure img{
        width: 40px;
        }
    .contentPage.Les_Menus p, .contentPage.La_Carte p{
        margin: 8px;
        font-size: 14px ;
        line-height: 1;
        }
    .contentPage.Les_Vins, .contentPage.Vins_au_verre, .contentPage.Le_Bar{
        line-height: 1.15;
    }
    .contentPage td{
        padding: 0;
        }
    .contentPage{
        padding: 0;
        }
    .contentPage.Les_Vins h3, .contentPage.Vins_au_verre h3, .contentPage.Le_Bar h3{
        font-size: 25px !important;
        margin-top: 0;
        margin-bottom: 0;
    }
    .contentPage.Le_Bar h3{
        font-size: 29px !important;
        margin-top: 0;
        margin-bottom: 0;
    }
    .contentPage.Le_Bar h4{
        font-size: 0.75rem;
    }
    .contentPage.Les_Vins h2, .contentPage.Vins_au_verre h2, .contentPage.Le_Bar h2{
        font-size: 35px;
        margin-top: 0;
        margin-bottom: 0;
        }
    .contentPage.Les_Vins{
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: stretch;
                align-items: stretch;
        }
    .contentPage.Le_Bar{
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        }
    .contentPage.Vins_au_verre{
        height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        -webkit-justify-content: center;
                justify-content: center;
        }
    .contentPage.Le_Bar{
        line-height: 1.2;
        }
    .contentPage.Vins_au_verre{
        line-height: 1.4;
        max-width: 145mm;
        }
    .contentPage.Vins_au_verre h3{
        line-height: 1.2;
        margin-top: 16px;
        margin-bottom: 12px;
        }
    .contentPage>.table{
        width: 100%;
    }
    .contentPage:not(.Le_Bar) h4::before,
    .contentPage:not(.Le_Bar) h4::after {
      display: inline-block;
      content: "";
      border-top: 2px solid #686868;
      width: 100%;
      margin: 0 1rem;
    }
    .contentPage:not(.Le_Bar) h4 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding: 0 120px;
    }
    .contentPage:not(.Le_Bar) h4 span{
      width: 100%;
    }
    .contentPage.Le_Bar h4{
      text-align: left !important;
      padding: 0;
      text-transform: uppercase;
      margin-top: 10px;
      padding-left: px;
    }
    .contentPage.Le_Bar h3{
      margin-top: 6px;
      width: 100%;
    }
    .contentPage h4 span, .contentPage h4 u{
      width: 100%;
    }
    .contentPage h2::before{
        display: inline-block;
        content: "";
        height: 20px;
        width: 65px;
        margin: 0 0.5rem;
        background-image: url(/static/media/orn-left.9c22537d.svg);
        background-repeat: no-repeat;
        background-position: right;
    }
    .contentPage h2::after {
        display: inline-block;
        content: "";
        height: 20px;
        width: 65px;
        margin: 0 0.5rem;
        background-image: url(/static/media/orn-right.3a9c73ce.svg);
        background-repeat: no-repeat;
        background-position: left;
    }
    .contentPage td{
        box-sizing: border-box;
        min-width: 62px;
    }
    .contentPage.Le_Bar td{
        box-sizing: border-box;
        min-width: 45px;
    }
    .contentPage>.table>table>tbody>tr>td:nth-last-child(3):not(:first-child){
        min-width: 150px;
        font-style: italic;
    }
    .contentPage>.table>table>tbody>tr>td:nth-last-child(1){
        border-left: 1px solid black;
    }
    .contentPage.La_Carte > .table > table > tbody > tr > td:nth-last-child(1){
        border-left: none;
        padding-left: 15px;
    }
    .contentPage h3 span{
        font-size: 8.5px;
    }
    .contentPage{
        -webkit-transform: translateY(-7px);
                transform: translateY(-7px);
        padding: 0 35px !important;
    }
    .contentPage.Le_Bar{
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
        padding: 0 20px !important;
        font-size: 0.8rem;
        max-width: 145mm;
    }
    .contentPage.Vins_au_verre h2{
        display: inline-block;
        width: 100%;
    }
    .contentPageContainer.Les_Menus:nth-child(2) h2{
        margin-bottom: 0;
        line-height: 0;
    }
    .contentPageContainer.Les_Menus:nth-child(2) h2{
        margin-bottom: 0;
        line-height: 0;
    }

    .contentPageContainer.Vins_au_verre h3{
        font-size: 2.6rem !important;
        width: 100%;
      }

    /* menu tradition */
    .contentPageContainer.Les_Menus:nth-child(2) p{
        margin: 7px;
    }
    .contentPage.La_Carte tr td{
        text-align: center;
    }

    .contentPage h2:first-child{
        margin-top: 0;
    }

    .contentPage h3:first-child{
        margin-top: 5px;
    }

    /* .laCarteSubContainer{
        background-color: blue;
    }
    .laCarteSubContainer .contentPageContainer:first-child .contentPage>*:first-child{
        background-color: rgb(255, 0, 234);
    }
    .laCarteSubContainer .contentPageContainer:first-child::before{
        content: "";
        height : 0;
        width : 0;
        border-top : 150px solid red;
        border-right : 150px solid transparent;
        float: left;
        top: 0;
    }
    .laCarteSubContainer .contentPageContainer:last-child .contentPage>*:last-child{
        background-color: rgb(255, 0, 115);
    }
    .triangle{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 150px solid red;
        border-right : 150px solid transparent;
       } */
}

@media screen{
    .MainFrame{
        width: 100%;
        height: 100vh;
        background-image: url(/static/media/le-balcon-logo-artisan-beige.b452f174.svg);
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    #contentBlock{
        overflow-y: auto;
    }
}

#mainTitle{
    text-align: center;
    font-size: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid white;
    margin: 0 50px;
    background-color: var(--Col_bck);
}

#mainTitle h1{
    margin: 0px;
    color: white;
}

@media (min-width: 560.97px) {
  #mainTitle h1::before{
      display: inline-block;
      content: "";
      height: 2rem;
      width: 6rem;
      margin: 0 0.5rem;
      background-image: url(/static/media/orn-left-white.2038fe2a.svg);
      background-repeat: no-repeat;
      background-position: right;
  }
  
  #mainTitle h1::after {
      display: inline-block;
      content: "";
      height: 2rem;
      width: 6rem;
      margin: 0 0.5rem;
      background-image: url(/static/media/orn-right-white.e4997043.svg);
      background-repeat: no-repeat;
      background-position: left;
  }
}

@media (max-width: 767.98px) {
  #mainTitle{
      text-align: center;
      font-size: 1.5rem;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
      border-bottom: 2px solid white;
      margin: 0 20px;
      background-color: var(--Col_bck);
  }
}
.categoryBlock{
    margin: 60px 50px 100px;
    width: auto;
    height:-webkit-min-content;
    height:min-content;
}

.categoryBlock h2{
    text-align: center;
    margin: 18px 0 30px;
    color: white;
    font-size: 2.8rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

.listBlock{
    margin: 0 auto;
    max-width: 800px;
    background-color:rgba(255, 255, 255);
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.20);
    padding-top: 30px;
    padding-bottom: 30px;
}

/* @media (min-width: 560.97px) {
  .categoryBlock h2::before{
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url('../img/orn-left-white.svg');
      background-repeat: no-repeat;
      background-position: right;
  }
  
  .categoryBlock h2::after {
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url('../img/orn-right-white.svg');
      background-repeat: no-repeat;
      background-position: left;
  }
} */
  

@media (max-width: 767.98px) {
    .categoryBlock{
        margin-inline: 12px;
    }
}
.entityBlock{
    padding: 20px 35px;
}
.entityBlock h3{
    color: var(--Col_main);
    font-size: 2.2rem;
    margin: 0.4rem;
}
.entityBlock .description{
    text-align: justify;
    font-size: 0.85rem;
}
.entityBlock .entityBlock-bottom{
    display: -webkit-flex;
    display: flex;
    margin: 12px 0;
}
.entityBlock .entityBlock-bottom *{
    margin: 0;
}
.entityBlock .entityBlock-bottom .label{
    text-align: center;
    font-weight: 600;
    font-size: 0.85rem;
}
.entityBlock .entityBlock-bottom h4{
    text-align: center;
    font-size: 2.2rem;
}
.entityBlock .entityBlock-bottom .entityOption{
    padding: 0 0 0 25px;
}
.entityBlock .entityBlock-bottom .entityOptions{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.separator{
    width: 100%;
    height: 1.5rem;
    background-image: url(/static/media/separator.c3f3f19a.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.SubCategoryTitle{
    padding: 0px 20px 0px 20px;
    text-align: center;
}
.SubCategoryTitle h3{
    font-size: 0.85rem;
    margin: 10px 0;
    border-radius: 4px;
    color: var(--Col_main);
    border-bottom: 2px solid var(--Col_sec);
    padding: 2px 8px 4px;
    font-weight: 700;
    text-transform: uppercase;
}
/* 
#SubCategoryTitle h3::before,
#SubCategoryTitle h3::after {
    display: inline-block;
    content: "";
    border-top: 2px solid white;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
} */
#SideBar{
    background-color:rgba(255, 255, 255);
    width: 360px;
    min-width: 300px;
    z-index: 99;
}
#burger-box{
    position: fixed;
    top: 0;
    right: 0;
    display: none;
}
#burger{
    width: 32px;
    height: 32px;
    position: relative;
    margin: 30px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #burger span{
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  
  #burger span:nth-child(1) {
    top: 0px;
  }
  
  #burger span:nth-child(2) {
    top: 12px;
  }
  
  #burger span:nth-child(3) {
    top: 24px;
  }
  
  #burger.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    background: var(--Col_main);
  }
  
  #burger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
    background: var(--Col_main);
  }
  
  #burger.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    background: var(--Col_main);
  }
@media (max-width: 1000px) {
  #SideBar{
      background-color:rgba(255, 255, 255);
      position: fixed;
      min-width: 300px;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
  }
  #SideBar.open{
    left: 0;
    transition: ease-in-out 800ms;
  }
  #SideBar.close{
    left: -100vw;
    transition: ease-in-out 800ms;
  }
  #burger-box{
    display: block;
  }
}
.Logo{
    background-repeat: no-repeat;
    background-position: center;
}
#SideBar .Logo{
    background-size: 80%;
    background-image: url(/static/media/le-balcon-logo-artisan-rouge-n.12e8f179.svg);
    width: 100%;
    min-height: 250px;
}
@media (max-width: 1000px) {
    #SideBar .Logo{
        background-size: 60%;
    }
}

.MenuElement{
    text-align: center;
    padding: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.MenuElement p{
    margin: 0.3rem 0;
}
.MenuElement:hover{
    cursor: pointer;
    background-color: var(--Col_contrast);
}

.MenuElement.selected{
    background-color: var(--Col_bck);
}
.MenuElement.selected p{
    display: block;
    color: white;
    text-align: center;
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
}

.MenuElement.selected p:after{
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    float: right;
    background-image: url(/static/media/arrow.62e5672a.svg);
    background-repeat: no-repeat;
    -webkit-transform: translateX(40px) translateY(4px);
            transform: translateX(40px) translateY(4px);
}

.Translate{
    display: -webkit-flex;
    display: flex;
    padding: 13px;
    z-index: 10;
}

@media print {
    body.A3.landscape { width: 420mm }
body.A3, body.A4.landscape { width: 297mm }
body.A4, body.A5.landscape { width: 210mm }
body.A5                    { width: 148mm }
body.letter, body.legal    { width: 216mm }
body.letter.landscape      { width: 280mm }
body.legal.landscape       { width: 357mm }

    .Translate{
    display: none;
    }
}
.Flag{
    width: 40px;
    height: 25px;
    background-color: white;
    margin: 0 3px;
    background-repeat: no-repeat;
    border: 1px solid white;
}
.Flag:hover{
    cursor: pointer;
}

.Flag.FR{
    background-image: url(/static/media/fr.86471783.svg);
}
.Flag.EN{
    background-image: url(/static/media/en.799ea806.svg);
}
.Flag.DE{
    background-image: url(/static/media/de.aad9041f.svg);
}
.Flag.selected::after{
    display: block;
    content: '';
    width: 100%;
    height: 50px;
    background-image: url(/static/media/select.b4ee9081.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.Flag.selected{
    border: 1px solid rgb(182, 0, 0);
}
