.SubCategoryTitle{
    padding: 0px 20px 0px 20px;
    text-align: center;
}
.SubCategoryTitle h3{
    font-size: 0.85rem;
    margin: 10px 0;
    border-radius: 4px;
    color: var(--Col_main);
    border-bottom: 2px solid var(--Col_sec);
    padding: 2px 8px 4px;
    font-weight: 700;
    text-transform: uppercase;
}
/* 
#SubCategoryTitle h3::before,
#SubCategoryTitle h3::after {
    display: inline-block;
    content: "";
    border-top: 2px solid white;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
} */