.Translate{
    display: flex;
    padding: 13px;
    z-index: 10;
}

@media print {
    body.A3.landscape { width: 420mm }
body.A3, body.A4.landscape { width: 297mm }
body.A4, body.A5.landscape { width: 210mm }
body.A5                    { width: 148mm }
body.letter, body.legal    { width: 216mm }
body.letter.landscape      { width: 280mm }
body.legal.landscape       { width: 357mm }

    .Translate{
    display: none;
    }
}