.Logo{
    background-repeat: no-repeat;
    background-position: center;
}
#SideBar .Logo{
    background-size: 80%;
    background-image: url('./images/le-balcon-logo-artisan-rouge-n.svg');
    width: 100%;
    min-height: 250px;
}
@media (max-width: 1000px) {
    #SideBar .Logo{
        background-size: 60%;
    }
}