
@media screen{
    .MainFrame{
        width: 100%;
        height: 100vh;
        background-image: url('./img/le-balcon-logo-artisan-beige.svg');
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
        display: flex;
        flex-direction: column;
    }
    #contentBlock{
        overflow-y: auto;
    }
}
