:root{
    /* --Col_main: #3b3640;
    --Col_sec: #f8005f;
    --Col_bck: #e49f5e;
    --Col_contrast: #f3cca2; */
    --Col_main: #3b3640;
    --Col_sec: #896394;
    --Col_bck: #ddc3b9;
    --Col_contrast: #b08ab8;
    --Col_main: #122f3c;
    --Col_sec: #E00000;
    --Col_bck: #d67b50;
    --Col_contrast: #ffe6c5;
    /* --Col_main: #3b3640;
    --Col_sec: #ac0a0a;
    --Col_bck: #370303;
    --Col_contrast: #e68a8a; */
}