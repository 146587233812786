.categoryBlock{
    margin: 60px 50px 100px;
    width: auto;
    height:min-content;
}

.categoryBlock h2{
    text-align: center;
    margin: 18px 0 30px;
    color: white;
    font-size: 2.8rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

.listBlock{
    margin: 0 auto;
    max-width: 800px;
    background-color:rgba(255, 255, 255);
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.20);
    padding-top: 30px;
    padding-bottom: 30px;
}

/* @media (min-width: 560.97px) {
  .categoryBlock h2::before{
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url('../img/orn-left-white.svg');
      background-repeat: no-repeat;
      background-position: right;
  }
  
  .categoryBlock h2::after {
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url('../img/orn-right-white.svg');
      background-repeat: no-repeat;
      background-position: left;
  }
} */
  

@media (max-width: 767.98px) {
    .categoryBlock{
        margin-inline: 12px;
    }
}