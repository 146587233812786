.Flag{
    width: 40px;
    height: 25px;
    background-color: white;
    margin: 0 3px;
    background-repeat: no-repeat;
    border: 1px solid white;
}
.Flag:hover{
    cursor: pointer;
}

.Flag.FR{
    background-image: url('./images/fr.svg');
}
.Flag.EN{
    background-image: url('./images/en.svg');
}
.Flag.DE{
    background-image: url('./images/de.svg');
}
.Flag.selected::after{
    display: block;
    content: '';
    width: 100%;
    height: 50px;
    background-image: url('../Flag/images/select.svg');
    background-position: center;
    background-repeat: no-repeat;
}
.Flag.selected{
    border: 1px solid rgb(182, 0, 0);
}