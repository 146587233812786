.MenuElement{
    text-align: center;
    padding: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.MenuElement p{
    margin: 0.3rem 0;
}
.MenuElement:hover{
    cursor: pointer;
    background-color: var(--Col_contrast);
}

.MenuElement.selected{
    background-color: var(--Col_bck);
}
.MenuElement.selected p{
    display: block;
    color: white;
    text-align: center;
    transform: translateX(8px);
}

.MenuElement.selected p:after{
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    float: right;
    background-image: url('./images/arrow.svg');
    background-repeat: no-repeat;
    transform: translateX(40px) translateY(4px);
}
