#mainTitle{
    text-align: center;
    font-size: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    border-bottom: 2px solid white;
    margin: 0 50px;
    background-color: var(--Col_bck);
}

#mainTitle h1{
    margin: 0px;
    color: white;
}

@media (min-width: 560.97px) {
  #mainTitle h1::before{
      display: inline-block;
      content: "";
      height: 2rem;
      width: 6rem;
      margin: 0 0.5rem;
      background-image: url('../img/orn-left-white.svg');
      background-repeat: no-repeat;
      background-position: right;
  }
  
  #mainTitle h1::after {
      display: inline-block;
      content: "";
      height: 2rem;
      width: 6rem;
      margin: 0 0.5rem;
      background-image: url('../img/orn-right-white.svg');
      background-repeat: no-repeat;
      background-position: left;
  }
}

@media (max-width: 767.98px) {
  #mainTitle{
      text-align: center;
      font-size: 1.5rem;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
      border-bottom: 2px solid white;
      margin: 0 20px;
      background-color: var(--Col_bck);
  }
}