.entityBlock{
    padding: 20px 35px;
}
.entityBlock h3{
    color: var(--Col_main);
    font-size: 2.2rem;
    margin: 0.4rem;
}
.entityBlock .description{
    text-align: justify;
    font-size: 0.85rem;
}
.entityBlock .entityBlock-bottom{
    display: flex;
    margin: 12px 0;
}
.entityBlock .entityBlock-bottom *{
    margin: 0;
}
.entityBlock .entityBlock-bottom .label{
    text-align: center;
    font-weight: 600;
    font-size: 0.85rem;
}
.entityBlock .entityBlock-bottom h4{
    text-align: center;
    font-size: 2.2rem;
}
.entityBlock .entityBlock-bottom .entityOption{
    padding: 0 0 0 25px;
}
.entityBlock .entityBlock-bottom .entityOptions{
    display: flex;
    justify-content: flex-end;
}