/* Resets */
*{
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
@media screen{
  .App{
      position: relative;
      background-color: var(--Col_bck);
      position: fixed;
      height: 100%;
      width:100vw;
      display: flex;
    }
  .contentPage.La_Carte>p:first-child{
    display: none;
  }
  .contentPage.Vins_au_verre>p:first-child{
    display: none;
  }
  .contentPage.Les_Vins>p:first-child{
    display: none;
  }
}
body{
  margin: 0;
}
  ::-webkit-scrollbar{
    width: 8px;
    height: 8px;
    padding: 12px;
  }
  ::-webkit-scrollbar-thumb{
    background-color: var(--Col_main);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background-color: #570000;
  }
  ::-webkit-scrollbar-track{
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: inset 7px 6.9px 12px 0px #D1D1D1;
    padding: 12px;
  }

  .InnerWindow{
  width:100%;
  }
  


  /* PAGE STYLING____________________________________________________________ */

  .contentPage h2 {
  font-size: 2.7rem;
  margin: 0;
  }
  .contentPage p {
  margin: 0;
  }
  .contentPage h2:not(:first-child) {
  margin-top: 50px;
  }
  .contentPage h2:first-child {
  margin-top: 20px;
  }
  .contentPage h3 {
  text-align: left !important;
  font-size: 2.2rem;
  margin-bottom: 0;
  height: fit-content;
  border-bottom: 4px solid #686868;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0 10px;
  }
  .contentPage h3 span{
  font-size: 0.75rem;
  text-align: right;
  }
  .contentPage tr:not(:first-child) td h3 {
  margin-top: 50px;
  }


  #contentBlock{
  width: 100%;
  height: 100%;
  padding: 0 12px 45px;
  font-size: 0.85rem;
  }
  .contentPage.Les_Menus figure , .contentPage.La_Carte figure{
  display: flex;
  justify-content: center;
  width: 100% !important;
  }
  .contentPage.Les_Menus p, .contentPage.La_Carte p{
  margin: 12px;
  }
  .contentPage.Les_Menus h2, .contentPage.La_Carte h2{
  margin-bottom: 35px;
  }
  .contentPage.Les_Menus .text-big{
  font-size: 1.2rem;
  }
  .contentPage.Les_Menus figure img , .contentPage.La_Carte figure img{
  width: 55px;
  }
  .contentPage{
  max-width: 1000px;
  margin: auto;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.212);
  padding: 25px 25px 45px;
  margin-bottom: 45px;
  margin-top: 45px;
  }
  .contentPage tr td tr td{
  padding: 0;
  }
  .contentPage tr td tr td p{
  margin: 0;
  }
  .contentPage table{
  margin: 0;
  width: 100%;
  }
  .contentPage figure{
  margin: 0;
  }
  .contentPage tbody{
  width: 100%;
  }
  .contentPage tr{
  width: 100%;
  }
  .contentPage td{
  box-sizing: border-box;
  min-width: 80px;
  padding: 10px 12px;
  width: 100%;
  }
  .contentPage td p{
  width: 100%;
  }
  .text-tiny{
  font-variant-position: super; 
  }


  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr:first-child{
  display: none;
  }
  .contentPageContainer.Vins_au_verre h3{
    font-size: 3rem;
  }

@media screen AND (min-width: 1000px) {
  .contentPage h2::before{
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url('./components/MainFrame/img/orn-left.svg');
      background-repeat: no-repeat;
      background-position: right;
  }
  
  .contentPage h2::after {
      display: inline-block;
      content: "";
      height: 1.4rem;
      width: 5rem;
      margin: 0 0.5rem;
      background-image: url('./components/MainFrame/img/orn-right.svg');
      background-repeat: no-repeat;
      background-position: left;
  }
  .contentPage:not(.Le_Bar) h4::before,
  .contentPage:not(.Le_Bar) h4::after {
    display: inline-block;
    content: "";
    border-top: 2px solid #686868;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-0.3rem);
  }
  .contentPage.Le_Bar h4
  {
    text-align: left !important;
    padding-left: 15px;
    margin-bottom: 0;
  }

  .contentPage>.table>table>tbody>tr>td:nth-last-child(2):not(:first-child){
    min-width: 100px;
  }
  .contentPage>.table>table>tbody>tr>td:nth-last-child(3):not(:first-child),.contentPage>.table>table>tbody>tr>td:nth-last-child(4):not(:first-child){
    min-width: 150px;
  }
}

.contentPage.La_Carte tr{
  display: flex;
  justify-content: center;
  align-items: center;
  }
.contentPage.La_Carte tr td{
  width: fit-content;
  }
.contentPage.La_Carte tr td:nth-child(2){
  font-size: 1.6rem;
  font-family: 'Romanesco', sans-serif;
  font-weight: 500;
  transform: translateY(-3px);
  }


@media screen AND (max-width:1000px) {
  .contentPage.Vins_au_verre tr td:nth-child(2){
    display: none;
  }
  h4{
    font-size: 1.1rem;
  }
  .contentPage.Le_Bar h4{
    margin-bottom: 0;
  }

  .contentPage td p:empty{
    display: none;
  }
  .contentPage>.table tr{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .contentPage>.table>table>tbody>tr>td:first-child{
    font-size: 1.1rem !important;
    padding-bottom: 20;
    width: 100%;
  }
  .contentPage>.table>table>tbody>tr>td:first-child tr>td:nth-child(2){
    display: none;
  }

  .contentPage>.table tr>td:not(first-child){
    width: auto;
    min-width: fit-content;
  }

  .contentPage h3 span{
    display: none;
  }
  .contentPage h3 {
    text-align: center !important;
    font-size: 2.4rem;
    display: flex;
    justify-content: center;
  }

  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr:first-child{
    font-size: 0.75rem !important;
    width: 100%;
    font-weight: 600;
    display: flex;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr:not(:first-child){
    font-size: 0.9rem !important;
    width: 100%;
    display: flex;
  }

  .contentPage>.table>table>tbody>tr:after{
    display: block;
      content: "";
      height: 18px;
      width: 100%;
      margin-top: 12px;
      background-image: url('./components/MainFrame/img/orn-right.svg');
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 45px;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr p{
    text-align: center;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr{
    padding: 10px;
    text-align: center;
    width: auto;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child){
    border-radius: 32px;
    text-align: center !important;
    width: 25%;
    padding: 0;
  }
  .contentPage>.table>table>tbody>tr>td:not(:first-child) tr{
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .contentPage.Les_Menus p, .contentPage.La_Carte p{
    font-size: 1.1rem;
  }
  .contentPage.Les_Menus .text-big{
    font-size: 1.2rem;
  }
}


.contentPage.Vins_au_verre h2{
  display: none;
  width: 100%;
}