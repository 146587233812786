#SideBar{
    background-color:rgba(255, 255, 255);
    width: 360px;
    min-width: 300px;
    z-index: 99;
}
#burger-box{
    position: fixed;
    top: 0;
    right: 0;
    display: none;
}
#burger{
    width: 32px;
    height: 32px;
    position: relative;
    margin: 30px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #burger span{
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  #burger span:nth-child(1) {
    top: 0px;
  }
  
  #burger span:nth-child(2) {
    top: 12px;
  }
  
  #burger span:nth-child(3) {
    top: 24px;
  }
  
  #burger.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    background: var(--Col_main);
  }
  
  #burger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
    background: var(--Col_main);
  }
  
  #burger.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    background: var(--Col_main);
  }
@media (max-width: 1000px) {
  #SideBar{
      background-color:rgba(255, 255, 255);
      position: fixed;
      min-width: 300px;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
  }
  #SideBar.open{
    left: 0;
    transition: ease-in-out 800ms;
  }
  #SideBar.close{
    left: -100vw;
    transition: ease-in-out 800ms;
  }
  #burger-box{
    display: block;
  }
}