
@media print {
    .contentPage.La_Carte>p:first-child{
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
    }
    .contentPage.Vins_au_verre>p:first-child{
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
    }
    .contentPage.Les_Vins>p:first-child{
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        width: 100%;
    }
    body{
        margin: 0;
        width: 100vw;
    }
    #root, #app, .InnerWindow{
        height: fit-content;
    }
    /* html{
        background-color: blue;
    } */
    html *{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
    h1,#mainTitle,.Translate,#SideBar{
        display:  none;
    }
    .contentPage{
        margin: 0;
    }
    .contentPageContainer{
        margin: 0;
        overflow: hidden;
        /* background-color: red;
        border: 3px solid black; */
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -13px;
        top: -1px;
    }
    .contentPageContainer.La_Carte{
        height: fit-content;
    }
    .contentPageContainer.La_Carte:first-child{
        margin-bottom: 30px;
    }
    .laCarteSubContainer{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    /* .contentPageContainer.La_Carte:nth-child(even){
            align-items: flex-start;
    }
    .contentPageContainer.La_Carte:nth-child(odd){
            align-items: flex-end;
            margin-bottom: 40px;
    } */
    .contentPageContainer *{
        max-width: 100vw;
        max-height: 100vh;
    }
    .contentPage{
        width: 100vw;
        box-shadow: none;
    }
    .contentPage.Les_Menus h2, .contentPage.La_Carte h2{
        margin-top: 0;
        font-size: 40px;
        margin-bottom: 5px !important;
        }
    .contentPage.La_Carte figure img{
        width: 40px;
        }
    .contentPage.Les_Menus p, .contentPage.La_Carte p{
        margin: 8px;
        font-size: 14px ;
        line-height: 1;
        }
    .contentPage.Les_Vins, .contentPage.Vins_au_verre, .contentPage.Le_Bar{
        line-height: 1.15;
    }
    .contentPage td{
        padding: 0;
        }
    .contentPage{
        padding: 0;
        }
    .contentPage.Les_Vins h3, .contentPage.Vins_au_verre h3, .contentPage.Le_Bar h3{
        font-size: 25px !important;
        margin-top: 0;
        margin-bottom: 0;
    }
    .contentPage.Le_Bar h3{
        font-size: 29px !important;
        margin-top: 0;
        margin-bottom: 0;
    }
    .contentPage.Le_Bar h4{
        font-size: 0.75rem;
    }
    .contentPage.Les_Vins h2, .contentPage.Vins_au_verre h2, .contentPage.Le_Bar h2{
        font-size: 35px;
        margin-top: 0;
        margin-bottom: 0;
        }
    .contentPage.Les_Vins{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        }
    .contentPage.Le_Bar{
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        }
    .contentPage.Vins_au_verre{
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        }
    .contentPage.Le_Bar{
        line-height: 1.2;
        }
    .contentPage.Vins_au_verre{
        line-height: 1.4;
        max-width: 145mm;
        }
    .contentPage.Vins_au_verre h3{
        line-height: 1.2;
        margin-top: 16px;
        margin-bottom: 12px;
        }
    .contentPage>.table{
        width: 100%;
    }
    .contentPage:not(.Le_Bar) h4::before,
    .contentPage:not(.Le_Bar) h4::after {
      display: inline-block;
      content: "";
      border-top: 2px solid #686868;
      width: 100%;
      margin: 0 1rem;
    }
    .contentPage:not(.Le_Bar) h4 {
      display: flex;
      align-items: center;
      padding: 0 120px;
    }
    .contentPage:not(.Le_Bar) h4 span{
      width: 100%;
    }
    .contentPage.Le_Bar h4{
      text-align: left !important;
      padding: 0;
      text-transform: uppercase;
      margin-top: 10px;
      padding-left: px;
    }
    .contentPage.Le_Bar h3{
      margin-top: 6px;
      width: 100%;
    }
    .contentPage h4 span, .contentPage h4 u{
      width: 100%;
    }
    .contentPage h2::before{
        display: inline-block;
        content: "";
        height: 20px;
        width: 65px;
        margin: 0 0.5rem;
        background-image: url('./components/MainFrame/img/orn-left.svg');
        background-repeat: no-repeat;
        background-position: right;
    }
    .contentPage h2::after {
        display: inline-block;
        content: "";
        height: 20px;
        width: 65px;
        margin: 0 0.5rem;
        background-image: url('./components/MainFrame/img/orn-right.svg');
        background-repeat: no-repeat;
        background-position: left;
    }
    .contentPage td{
        box-sizing: border-box;
        min-width: 62px;
    }
    .contentPage.Le_Bar td{
        box-sizing: border-box;
        min-width: 45px;
    }
    .contentPage>.table>table>tbody>tr>td:nth-last-child(3):not(:first-child){
        min-width: 150px;
        font-style: italic;
    }
    .contentPage>.table>table>tbody>tr>td:nth-last-child(1){
        border-left: 1px solid black;
    }
    .contentPage.La_Carte > .table > table > tbody > tr > td:nth-last-child(1){
        border-left: none;
        padding-left: 15px;
    }
    .contentPage h3 span{
        font-size: 8.5px;
    }
    .contentPage{
        transform: translateY(-7px);
        padding: 0 35px !important;
    }
    .contentPage.Le_Bar{
        transform: translateY(-10px);
        padding: 0 20px !important;
        font-size: 0.8rem;
        max-width: 145mm;
    }
    .contentPage.Vins_au_verre h2{
        display: inline-block;
        width: 100%;
    }
    .contentPageContainer.Les_Menus:nth-child(2) h2{
        margin-bottom: 0;
        line-height: 0;
    }
    .contentPageContainer.Les_Menus:nth-child(2) h2{
        margin-bottom: 0;
        line-height: 0;
    }

    .contentPageContainer.Vins_au_verre h3{
        font-size: 2.6rem !important;
        width: 100%;
      }

    /* menu tradition */
    .contentPageContainer.Les_Menus:nth-child(2) p{
        margin: 7px;
    }
    .contentPage.La_Carte tr td{
        text-align: center;
    }

    .contentPage h2:first-child{
        margin-top: 0;
    }

    .contentPage h3:first-child{
        margin-top: 5px;
    }

    /* .laCarteSubContainer{
        background-color: blue;
    }
    .laCarteSubContainer .contentPageContainer:first-child .contentPage>*:first-child{
        background-color: rgb(255, 0, 234);
    }
    .laCarteSubContainer .contentPageContainer:first-child::before{
        content: "";
        height : 0;
        width : 0;
        border-top : 150px solid red;
        border-right : 150px solid transparent;
        float: left;
        top: 0;
    }
    .laCarteSubContainer .contentPageContainer:last-child .contentPage>*:last-child{
        background-color: rgb(255, 0, 115);
    }
    .triangle{
        display : inline-block;
        height : 0;
        width : 0;
        border-top : 150px solid red;
        border-right : 150px solid transparent;
       } */
}